/* eslint-disable fp/no-mutation */
/* eslint-disable no-console */

import axios, { AxiosPromise } from 'axios';
import qs from 'qs';

function setCookie(key: string, value: string): void {
  const date = new Date();

  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);

  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${key}=${value}; ${expires}; path=/; samesite=strict`;
}

export function setCredentialsToCookie(email: string, password: string): void {
  if (typeof document !== 'undefined') {
    setCookie('email', email);
    setCookie('password', password);
  } else {
    console.error('document is undefined. Can not write credentials to a cookie');
  }
}

export function clearAuthCookies(): void {
  if (typeof document !== 'undefined') {
    document.cookie = 'email=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = 'password=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
  } else {
    console.error('document is undefined. Can not remove credentials from a cookie');
  }
}

export function submitLogin(email: string, password: string): Promise<AxiosPromise> {
  const formValues = {
    email,
    password,
  };

  return axios.post('/api/login', qs.stringify(formValues));
}

export async function isLoggedIn(): Promise<boolean> {
  const { email, password } = getCredentials();

  if (!email || !password) {
    clearAuthCookies();

    return Promise.resolve(false);
  }

  try {
    await submitLogin(email, password);

    return true;
  } catch {
    clearAuthCookies();

    return false;
  }
}

interface Credentials {
  email: string;
  password: string;
}

export function getCredentials(): Credentials {
  const email = getCookie('email');
  const password = getCookie('password');

  return { email, password };
}

// source: https://www.w3schools.com/js/js_cookies.asp
function getCookie(cname): string {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
